import { MatchType } from 'src/domains/sportsbook/shared/Types';
import { FiltersSkin } from 'src/domains/layouts/webview/components/filters/FiltersSkin';
import { MetaOpenGraphTagsType } from 'src/domains/layouts/state/headerMeta/HeaderMeta';
import { SelectOptionType } from 'src/domains/players/shared/Types';
import { CurrencyType } from 'src_common/common/amount/website-money/currency';
import { Response200Type as FooterCopyrightTemporary } from 'src/api_openapi/generated/openapi_website_cms_getCopyrightFooter';
import { Response200Type as ContactUsTemporary } from 'src/api_openapi/generated/openapi_website_cms_getContactUsElements';
import { CollectionType, CollectionWithBingoType, ProviderType } from 'src/domains/casino/types/types';
import { CountryCodeType } from 'src/domains/layouts/config/countries';
import { RightHandSideViewType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { Amount } from 'src_common/common/amount/Amount';
import { ConfigFeaturesType, OpenBankingProvidersType } from 'src/domains/common/configFeatures/ConfigFeatures';

// CASINO TYPES
type GameWrapperType = 'slider' | 'slider-grid' | 'square' | 'rectangle' | 'numeric-trending';
interface GameThumbnailSettingsType {
    wrapperType: GameWrapperType;
    showModalOnMobile: boolean;
    showLikeAndInfo: boolean;
    showRealTimeLobby: boolean;
    minBetOnly: boolean;
    showJackpotBar: boolean;
}
type GameThumbnailsSettingsType = Record<CollectionType, GameThumbnailSettingsType>;
export type SocketPragmaticType = null | {
    host: string;
    casinoId: string;
};

// GROWTH TYPES
export type HeaderLinksType =
    | 'sports'
    | 'in-play'
    | 'lucky6'
    | 'mcbookie-blog'
    | 'star-news'
    | 'rhino-promotions'
    | 'dragonbet-blog'
    | CollectionWithBingoType;
export interface LanguageDetailsType {
    langCode: string;
    langName: string;
}
export type LayoutType = 'primary' | 'secondary';
export type SignupType = 'primary' | 'secondary';

export type PromotedEventRouteType =
    | 'aks-big-prices'
    | 'specials'
    | 'betzoneevents'
    | 'starevents'
    | 'yeeehaaaevents'
    | 'welshevents'
    | 'promoted-events'
    | '';

// promotions for Rhino as it has different icon here
export type MobileFooterLinksType =
    | 'cash-out'
    | 'football-european'
    | 'football'
    | 'home'
    | 'in-play'
    | 'notifications'
    | 'offers'
    | 'promos'
    | 'racing'
    | 'sports-list'
    | 'sportsbook'
    | 'extra'
    | Exclude<CollectionWithBingoType, 'virtuals'>;

type SeoContentType = Record<
    string,
    {
        header: string;
    }
> | null;

type SeoMetaType = Record<
    'homepage' | 'inplay' | 'sports' | 'promoted-events' | CollectionType,
    {
        title: string;
        description: string;
    } | null
>;
type SeoMetaSportType = Record<
    string,
    {
        title: string;
        description: string;
    }
>;

export type UsefulLinksKeysType =
    | 'about-us'
    | 'responsible-gambling'
    | 'faq'
    | 'privacy-policy'
    | 'terms-and-conditions'
    | 'betting-rules'
    | 'give-feedback'
    | 'affiliates';

export type FooterUsefulLinksItemType = {
    id: UsefulLinksKeysType;
    order: number;
    to: RightHandSideViewType | string;
    target?: '_blank' | '_self' | '_parent' | '_top' | null;
    route?: string;
    isStaticLink?: boolean;
};

export type FooterUsefulLinksType = Partial<Record<UsefulLinksKeysType, FooterUsefulLinksItemType>>;

type ImageOptimizationType =
    | {
          type: 'autoUrl'; // for prod
      }
    | {
          type: 'baseUrl'; // for localhost & stage
          url: string;
      }
    | null; // disable optimization

// PLAYERS TYPES

export type PaysafePaymentProviderType = {
    type: 'paysafe';
    apiKey: string;
    environment: 'TEST' | 'LIVE';
    cardAccounts: { GBP: string; EUR: string };
};

export type PaymentProvidersType =
    | { type: 'realex' }
    | { type: 'swiftyGlobalProvider' }
    | { type: 'praxis' }
    | PaysafePaymentProviderType;

export type ContactPreferencesType = 'email' | 'sms' | 'phone' | 'whatsApp';

// SPORTSBOOK TYPES
export type ListOfSportsType =
    | 'americanfootball'
    | 'australianrules'
    | 'badminton'
    | 'baseball'
    | 'basketball'
    | 'boxing'
    | 'cricket'
    | 'cycling'
    | 'darts'
    | 'esports'
    | 'football'
    | 'formulaone'
    | 'gaelicfootball'
    | 'gaelichurling'
    | 'golf'
    | 'greyhoundracing'
    | 'handball'
    | 'horseracing'
    | 'horseracingantepost'
    | 'icehockey'
    | 'internationalhorseracing'
    | 'mixedmartialarts'
    | 'motorsport'
    | 'olympics'
    | 'politics'
    | 'rugbyleague'
    | 'rugbyunion'
    | 'snooker'
    | 'tabletennis'
    | 'tennis'
    | 'tennisdoubles'
    | 'tvspecials'
    | 'volleyball';

export type LiveTrackerType = null | {
    providerName: string;
    scriptSrcUrl: string;
};

// the first item in an array is a default language for the operator
export type LanguagesAvailableType = Array<LanguageDetailsType>; // monte

export type ConfigType = ConfigFeaturesType;

//TODO - do skasowania
export interface ConfigOldType {
    // CASINO CONFIG
    /* A */
    /** @description define where antillephone icon should be displayed' */
    antillephoneDisplaySector: 'license' | 'responsible-gambling' | null;
    /* C */
    /** @description set casino jackpot games for each operator */
    casinoJackPot: boolean;
    /** @description set casino mini games for each operator */
    casinoMiniGames: boolean;
    /* G */
    /** @description turn on/off game status bar for slots */
    gameStatusBar: boolean;
    /** @description defines what features should be displayed on SingleGameExt */
    gameThumbnailSettings: GameThumbnailsSettingsType;
    /* H */
    /** @description turn on/off users name in continue playing headline */
    hasContinuePlayingName: boolean;
    /** @description turn on/off promo carousel view */
    hasHeadlinePromo: boolean;
    /** @description turn on/off games on homepage */
    hasGamesOnHomepage: boolean;
    /** @description show / hide all casino game on Christmas day */
    hideAllCasinoGamesOnChristmas: boolean;
    /* I */
    /** @description turn on/of image optimization with Cloudflare */
    imageOptimization: ImageOptimizationType;
    /* R */
    /** @description list of providers that require reload game after deposit */
    reloadGameAfterDepositForProviders: Array<ProviderType>;
    /* S */
    /** @description DGA for live casino */
    socketPragmatic: SocketPragmaticType;

    // GROWTH CONFIG
    /* A */
    /** @description New account drawer */
    accountDrawerNew: boolean;
    /** @description Link for mobile app in store */
    appDownloadLink: {
        appStoreLink: string | null;
        googlePlayLink: string | null;
    };
    /* C */
    /** @description Operator's cookiebot cookie consent banner id. */
    cookieBotId: string | null;
    /** @description contact possibilities for Contact Us section in the main footer */
    contactDetails: {
        phoneNumber: string | null;
        email: string | null;
        facebookLink: string | null;
        twitterLink: string | null;
        instagramLink: string | null;
    };
    /** @description temporary contact us list */
    contactUsTemporaryDefaultMock: ContactUsTemporary;
    /* F */
    /** @description set proper filters version */
    filtersVersion: {
        /** @description Filters on Bets tab => Betslip | My bets */
        betSlipTab: FiltersSkin;
        casino: FiltersSkin;
        /** @description only football special filter skin */
        football: FiltersSkin;
        raceCardMain: FiltersSkin;
        inPlay: FiltersSkin;
        startingSoon: FiltersSkin;
        eventsUpcoming: FiltersSkin;
        eventsOnLater: FiltersSkin;
        sportEvent: FiltersSkin;
        competitionPage: FiltersSkin;
        transactionHistory: FiltersSkin;
    };
    /** @description temporary footer copyright */
    footerCEGLinks: {
        iconUrl: string;
        verificationUrl: string;
    } | null;
    footerCopyrightTemporaryDefaultMock: Omit<FooterCopyrightTemporary, 'universe'>;
    footerUsefulLinks: FooterUsefulLinksType;
    /** @description FreshDesk chat config */
    freshDeskChat: {
        token: string;
        host: string;
        widgetUuid: string;
    } | null;

    /** @description fullstory id to track users on website */
    fullstory: string | null;
    /* G */
    /** @description google tag to google tag manager (google analytics and google optimize should be added through google tag manager panel) for Prod and non Prod env */
    gtm: string;
    /** @description google optimize experiment id for dev (google optimize should be added through google tag manager panel) */
    googleExperimentIdDev: string;
    /** @description google optimize experiment id for production (google optimize should be added through google tag manager panel) */
    googleExperimentIdProd: string;
    /* H */
    /** @description has to collect info about signup on mobile app*/
    sendInfoAboutSignUpToMobileApp: boolean;
    /** @description turn on/off promo carousel view */
    hasCarouselPromo: boolean;
    /** @description link general terms & conditions in footer copyright text */
    hasFooterCopyrightTCLink: boolean;
    /** @description has XCM script in footer */
    hasXcmScript: boolean;
    /** @description  header links for universes */
    headerLinks: Array<HeaderLinksType>;
    /** @description ... */
    headerMetaScale:
        | 'width=device-width, initial-scale=1, shrink-to-fit=no'
        | 'width=device-width, initial-scale=1, user-scalable=no'
        | 'width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no, viewport-fit=cover'
        | 'width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no'
        | 'width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover';
    /** @description ... */
    headerMetaTitleName: string;
    /** @description Facebook domain verification meta tag */
    headerMetaFacebookDomainVerification: string | null;
    /** @description link to the operator's blog / news section */
    headerSpecialLink: 'mcbookieblog' | 'starsportsbet' | null;
    /** @description sports which has to be hidden */
    hiddenSportsList: string[];
    /** @description flag to hide sports with no events on A-Z sports list */
    hideEmptySports: boolean;
    /* I */
    /** @description add icon ios in headerMeta */
    isDarkUniverse: boolean;
    /** @description show Gambling Comission logo in Responsible Gambling footer section */
    isGamblingComissionRGLogo: boolean;
    /** @description Show no connection page when user has no internet connection */
    isNoConnectionPageVisible: boolean;
    /** @description turn on/off seo pages content */
    isSeoPagesContentOn: boolean;
    /** @description Show popup Reality Check for all pages excluded casino */
    isRealityCheckAvailable: boolean;
    /* L */
    /** @description languages available for operator */
    languagesAvailable: LanguagesAvailableType;
    /** @description Specifies which layout to use:
     *
     * primary: star based layout
     *
     * secondary: LuckyKing based layout */
    layout: LayoutType;
    /** @description Specifies which layout to use on homepage */
    layoutHomepage: 'primary' | 'secondary';
    /** @description keeps routes and components of "Limits & Responsible Gambling" */
    limitsAndResponsibleGambling: boolean;
    /** @description keeps routes and components of "Limits & Responsible Gambling" on sign up */
    limitsAndResponsibleGamblingOnSignup: boolean;
    /** @description Specifies whether live tracker should be displayed for football outrights */
    liveTrackerForFootballOutrights: boolean;
    /* M */
    /** @description apply meta open graph in html header */
    metaOpenGraph: Array<MetaOpenGraphTagsType> | null;
    /** @description mobile footer links for all universes */
    mobileFooterLinks: Array<MobileFooterLinksType>;
    /* N */
    /** @description sports to show in popular section. If needed it can be expanded for new sports */
    navigationPopular: Array<'promotedevents' | ListOfSportsType> | null;
    /** @description show favourites section */
    navigationFavourites: boolean;
    /** @description show recently viewed section */
    navigationRecentlyViewed: boolean;
    /** @description set mobile navigation links different to the top nav/header ones or the same like top nav/header ones */
    navigationMobileLinks: 'special-links' | 'header-links';
    /** @description switch between old and new pragmatic integration */
    newPragmatic: boolean;
    /** @description turn on new view for Next Races (old Next Off) */
    nextRacesNew: boolean;
    /** @description switch to render notifications */
    notificationsList: boolean;
    /* O */
    /** @description Full name of each operator */
    operatorFullName: string;
    /** @description Routes from external server with the same domain which are used for promotion */
    outsideAllowedRoutes: Array<string>;
    /* P */
    /** @description add additional elements and styles when 404 error page is not a default one */
    page404Default: boolean;
    /** @description show premier league iframe on sports/premier-league endpoint */
    premierLeagueIframe: boolean;
    /** @description show Problem Gambling link in responsible gambling section */
    problemGambling: boolean;
    /** @description Show promoted events in LHS menu and main page and set route */
    promotedEvents: {
        label: string;
        route: PromotedEventRouteType;
    };
    /* R */
    /** @description turns on new component (with increased silk) for horse racing selection */
    raceSummaryRowHorseRacingNew: boolean;
    /** @description show responsible gambling logotypes */
    responsibleGamblingVerificationScript: 'default' | 'swifty-type';
    /* S */
    /** @description seo headers and content */
    seoContent: SeoContentType;
    /** @description seo title, description */
    seoMeta: SeoMetaType | null;
    /** @description seo headers, title, description for sport */
    seoMetaSport: SeoMetaSportType | null;
    /** @description set scoreboard mode:
     * basic: only score and time
     * standard: old scoreboard with animation
     * liveTracker: new live tracker with animation
     * lSport: LSport tennis and golf scoreboard
     */
    scoreboard: {
        basic: Array<ListOfSportsType> | null;
        standard: Array<ListOfSportsType> | null;
        liveTracker: Array<ListOfSportsType> | null;
        /** * (LSport now is Statscore). For LSport eventId we have to add prefix m:eventId => example: "m:301832" */
        lSport: Array<ListOfSportsType> | null;
        imgArena: 'golf' | null;
    };
    /** @description Sentry error tracker link */
    sentryUrl: string | null;
    /** @description header meta css */
    siteVerificationMetaMap: {
        name: string;
        content: string;
    } | null;
    /** @description Add social widget on RHS menu */
    socialWidgets: {
        twitter: {
            url: string;
            theme: 'light' | 'dark';
        } | null;
    };
    /** @description groupId (for the whole platform) from Statscore */
    statsCoreGroupId: {
        /** @description preMatchPro - pre match widget */
        preMatchPro: string;
        /** @description liveMatchPro - live match widget */
        liveMatchPro: string;
    };
    /** @description ... */
    stickyDesktopHeader: boolean;
    /* T */
    /** @description option to download T&C */
    termsAndConditionsDownloadLink: boolean;
    /** @description trader chat */
    traderChat: {
        /** @description turn on/off trader chat */
        isOn: boolean;
        /** @description turn on/off floating icon trader chat */
        isBubbleChatButton: boolean;
    };
    /* U */
    /** @description turn on component for unSupportedBrowsers */
    unSupportedBrowsers: boolean;
    /** @description set logo for unSupportedBrowsers component */
    unSupportedBrowsersLogo: 'StarsportsLogoIconWrapper' | 'NebetLogoWrapper' | 'McBookieLogoWrapper';
    /* W */
    /** @description enable showing weather data */
    weatherData: boolean;
    /** @description enable showing world cup 2022 iframe */
    worldCupIframe: boolean;

    // PLAYERS CONFIG
    /** @description account for help contact */
    accountHelperMail: string;
    /** @description prefix for account Id */
    accountIdPrefix: string;
    /** @description id for antillephone if exists */
    antillephoneId: string | null;
    /* C */
    /** @description defines published casino collections*/
    casinoPublishedCollections: Array<CollectionWithBingoType>;
    /** @description defines contact preferences options*/
    contactPreferences: Array<ContactPreferencesType>;
    /** @description only default country in signup */
    countryOnlyDefault: boolean;
    /** @description default country in sign up */
    countrySignUpDefault: CountryCodeType | null;
    /** @description diffrent account tabs*/
    cryptoOperatorAccountType: boolean;
    /** @description default currency */
    currencyDefault: CurrencyType;
    /** @description have default currency selected in signup */
    currencyDefaultSelectedInSignup: boolean;
    /** @description only default currency in signup */
    currencyOnlyDefault: boolean;
    /** @description list of currencys in signup */
    currencySignUpList: Array<CurrencyType>;
    /** @description disable customer funds protection tab*/
    customerFundsProtection: boolean;
    /* D */
    /** @description specify how many places after comma we want to have in value */
    decimalLength: number;
    /** @description specify how we will display stake input placeholder text */
    decimalFormatTextForPlaceholder: string;
    /** @description ui change for picking limits based on calendar or time window (rolling)  */
    depositLimitsTypes: 'calendarType' | 'rollingType';
    /** @description detect login after signup in mobile app */
    detectLoginInMobileApp: boolean;
    /* E */
    /** @description remove countries from signup dropdown */
    excludedCountriesFromSignUp: Array<string>;
    /** @description email to kyc warning */
    emailKycWarning: string;
    /* F */
    /** @description input label type in forgot password form */
    forgotPasswordInputLabel: 'default' | 'mcbookie';
    /** @description input type in forgot password form */
    forgotPasswordInputType: 'email' | 'text';
    /** @description detailed failed verification page in sign up journey */
    failedVerificationDetailed: boolean;
    /** @description turn on/off faq section */
    faqSection: {
        isOnForAuthorized: boolean;
        isOnForNonAuthorized: boolean;
    };
    /* H */
    /** @description turn on/off able to cashout */
    hasCashoutEnabled: boolean;
    /** @description turn on/off section with help in account */
    hasNeedHelpAccountSection: boolean;
    /** @description turn on/off able to change address */
    hasUserAddressChangeEnabled: boolean;
    /** @description turn on/off able to change contact number */
    hasUserContactNumberChangeEnabled: boolean;
    /** @description turn on/off able to change email address */
    hasUserEmailChangeEnabled: boolean;
    /* I */
    /** @description enable the account verification via SMS */
    isVerifyAccountWithSMSAvailable: boolean;

    /* L */
    /** @description autologin after reset password success */
    loginOnResetPasswordSuccess: boolean;
    /** @description license */
    licenseName: string;
    /** @description is lifeSpan active- */
    lifeSpanActive: boolean;
    /** @description Websockets urls to lifespan */
    lifeSpanSocketUrls: string | null;
    /* M */
    /** @description minimum deposit amount */
    minDepositAmount: Amount;
    /** @description minimum withdraw amount */
    minWithdrawAmount: Amount;
    /* N */
    /** @description show help and support section */
    navigationHelpSupport: boolean;
    /* P */
    /** @description show component with payout rules text */
    payoutRules: boolean;
    /** @description show default prefix and flag for personal details in account */
    prefixAndFlagDefaultForPersonalDetails: { id: string; prefix: string; label: string } | null;
    /** @description show default prefix for personal details in account */
    prefixAndPhoneNumberDefault: SelectOptionType;
    /** @description show dropdown for mobile prefixes in registration and settings */
    prefixMultiPhoneNumber: boolean;
    /** @description priority prefixes that are in the front of phone number prefixes list */
    priorityPrefixes: string[];
    /** @description Proof of Funds features */
    pof: boolean;

    /* R */
    /** @description reset password form */
    resetPasswordForm: 'ResetPasswordForm' | 'McBookieValidateAccountForm';
    /** @description set ringFencedFunds by default during signup */
    ringFencedFundsSetByDefault: boolean;
    /** @description turn on rolling deposit limits in signup and responsible gambling tab plus check it when user logs in */
    rollingNetDepositLimit: boolean;
    /** @description popup for users after adding new feature to operators - only when feature was created */
    rollingNetDepositLimitFirstTimePopupType: 'primary' | 'secondary';
    /* S */
    /** @description show KycStatus */
    showKycStatus: boolean;
    /** @description show Kyc warning in deposit tab */
    showKycDepositWaring: boolean;
    /** @description signUp disable button YourDetails */
    signUpDisableButtonYourDetails: boolean;
    /** @description ... */
    signUpFooterMail: string;
    /** @description ... */
    signUpShowPromoCode: boolean;
    /** @description turn on/off searching addres by postcode in singup procedure  */
    signUpAddressSearchByPostCode: boolean;
    /** @description signup checkbox for Terms & Conditions */
    signUpCheckboxTC: boolean;
    /** @description pass registration without checking age verification */
    signUpOmitAgeVerification: boolean;
    /** @description show second line option to choose during signup */
    signUpSecondAddressLine: boolean;
    /** @description show title option to choose during signup */
    signUpTitle: boolean;
    /** @description pass registration without checking age verification */
    signupType: SignupType;
    /** @description show signup privacy policy link on signup */
    signupPrivacyPolicyLink: boolean;
    /** @description show the SRG Questionnaire form modal */
    srgQuestionnaire: boolean;

    /** @description Show temporary deposit page */
    temporaryDepositPage: boolean;
    /** @description Show temporary withdrawal page */
    temporaryWithdrawalPage: boolean;
    /** @description -----FeaturesFlag => should be always FALSE-----  */
    timeoutToTestSignupFlow: boolean;
    /* U */
    /** @description Payment providers */
    usePaymentFormForUniverse: PaymentProvidersType;

    /** @description Open Banking Payment provider */
    useOpenBanking: OpenBankingProvidersType;

    /** @description changes way of adding money to the balance */
    usesCrypto: boolean;
    /** @description user can change Reality Check duration */
    userCanChangeRealityCheck: boolean;
    /* Z */
    /** @description Change to zip code name and signup validate dropdowns */
    zipCodeAndCountryCheck: boolean;

    // SPORTSBOOK CONFIG
    /* B */
    /** @description  set checkbox colors for Build Racecard */
    buildRacecardCheckboxColorMode: 'dark' | 'light';

    /* C */
    /** @description coupon for environment*/
    couponForEnv: 'couponsStar' | 'couponsMcBookie' | 'couponsNebet' | 'couponsDragonBet';
    /* D */
    /* E */
    /** @description get ids europeanEliteTemplate from template mcbookie */
    eventsEuropeanEliteMcbookie: boolean;
    /** @description if route is starEvents show only eventsOnLater in mid layout */
    eventsOnLaterHomePage: boolean;

    /* H */
    /** @description show icons and buttons for live event stream */
    hasEventLiveStreaming: boolean;
    /** @description turn on delay information in InPlayComponent */
    hasInPlayDelayInformation: boolean;
    /** @description turn on/off top up in betslip */
    hasTopUpEnabled: boolean;
    /* I */
    /** @description display alternative price boosts */
    isAlternativeRacingBoost: boolean;
    /** @description add icon ios in headerMeta */
    isAntePostReverse: boolean;
    /** @description should promo carousel be automatic */
    isAutomaticCarousel: boolean;
    /** @description decide if stream component on Special Event should be controlled by time */
    isStreamControlledByTime: boolean;
    /** @description decide if stream component should be visible for unauthorized users */
    isStreamVisibleForUnauthorized: boolean;
    /* L */
    /** @description live tracking scoreboard configuration - provider name and script src*/
    liveTracker: LiveTrackerType;
    /* N */
    /** @description universe has no betslip */
    noBetslip: boolean;
    /* O */
    /** @description default odds for anonymous user */
    oddsFormatShortDefault: 'f' | 'd';
    /* P */
    /** @description show price history line chart in racing */
    priceHistoryLineChart: boolean;
    /* R */
    /** @description -----FeaturesFlag => should be always FALSE----- */
    rab: boolean;
    /** @description description in YouDecideHeader (Unavailable for cashout) */
    rabCashoutAvailable: boolean;
    /** @description ... */
    rabHeaderIcon: 'BetBuilderIcon';
    /** @description title for rab header and bets */
    rabHeaderTitle: string;
    /** @description set switch between HR and GR next off */
    racingNextOffToggle: boolean;
    /** @description set names for racing page filter tabs */
    racingPageTabNames: 'default' | 'betzone-type';
    /* S */
    /** @description  if true, silk is displayed without question mark */
    shouldDisplaySilkWithoutQuestionMark: boolean;
    /** @description  decide if user has to place a bet to enable stream */
    shouldPlaceBetToWatchStream: boolean;
    /** @description show sort dropdown on Horse Racing Racecard */
    showHorseRacingSortDropdown: boolean;
    /** @description turn on slice name in race label */
    sliceNameRaceLabel: boolean;
    /** @description decide if GR sport meetings are splitted by country */
    sportGreyhoundMeetingsSplitByCountry: boolean;
    /** @description decide if HR sport meetings are splitted by country */
    sportHorseracingMeetingsSplitByCountry: boolean;
    /** @description decide if sports in LHS will have capitised names */
    sportsNamesCapitalized: boolean;
    /** @description show starting soon events on homepage */
    startingSoonEvents: boolean;
    /* T */
    /** @description enable Trap Challenge */
    trapChallenge: boolean;
    /* U */
    /** @description .... */
    universeSetup: Array<MatchType>;
    /** @description determines whether to validate the wallet address in BTC */
    withdrawValidateBtcAddress: boolean;
}

export type ConfigKeysType = keyof ConfigType;

export const configTypesDesc: Record<keyof ConfigType, string> = {
    // CASINO CONFIG
    /* A */
    antillephoneId: 'id for antillephone if exists',
    antillephoneDisplaySector: 'define where antillephone icon should be displayed',
    /* B */
    bonuseEngine: 'accounts bonuse engine',

    /* C */
    casinoJackPot: 'set casino jackpot games for each operator',
    casinoMiniGames: 'contact possibilities for Contact Us section in the main footer',
    /* G */
    gameStatusBar: 'turn on/off game status bar for slots',
    gameThumbnailSettings: 'defines what features should be displayed on SingleGameExt',
    /* H */
    hasContinuePlayingName: 'turn on/off users name in continue playing headline', // false only for LK but on designs it should be there - fix and remove
    hasHeadlinePromo: 'turn on/off headline carousel view',
    hasGamesOnHomepage: 'turn on/off games on homepage',
    hideAllCasinoGamesOnChristmas: 'show / hide all casino game on Christmas day.',
    /* I */
    imageOptimization: 'turn on/of image optimization with Cloudflare',
    /* R */
    reloadGameAfterDepositForProviders: 'list of providers that require reload game after deposit',
    /* S */
    socketPragmatic: 'DGA for live casino',

    // GROWTH CONFIG
    /* A */
    accountDrawerNew: 'New account drawer',
    appDownloadLink: 'Links for mobile app in store',
    /* C */
    cookieBotId: "Operator's cookiebot cookie consent banner id.",
    contactDetails: 'contact possibilities for Contact Us section in the main footer', // used only for LK email
    contactUsTemporaryDefaultMock: 'temporary contact us list',
    /* F */
    filtersVersion: 'set proper version for all filters across the website.',
    footerCEGLinks: 'set footer links for CEG verification',
    footerCopyrightTemporaryDefaultMock: 'temporary footer copyright',
    footerUsefulLinks: 'Useful links section in footer',
    freshDeskChat: 'Fresh Desk chat',
    fullstory: 'fullstory tool to track user behaviour on website ',
    /* G */
    gtm: 'google tag to google tag manager - prod and non prod',
    googleExperimentIdDev: 'google optimize experiment id for dev',
    googleExperimentIdProd: 'google optimize experiment id for production',
    /* H */
    sendInfoAboutSignUpToMobileApp: 'has to collect info about signup on mobile app',
    hasCarouselPromo: 'turn on/off promo carousel view',
    hasFooterCopyrightTCLink: 'link terms & conditions in footer copyright text',
    hasXcmScript: 'has XCM script in footer',
    headerLinks: 'header links for universes',
    headerMetaScale: '',
    headerMetaTitleName: '',
    headerMetaFacebookDomainVerification: 'Unique code for facebook domain verification meta tag',
    headerSpecialLink: "link to the operator's blog / news section",
    hiddenSportsList: 'sports which have to be hidden',
    hideEmptySports: 'flag to hide sports with no events on A-Z sports list',
    /* I */
    isDarkUniverse: 'add icon ios in headerMeta',
    isGamblingComissionRGLogo: 'show Gambling Comission logo in Responsible Gambling footer section',
    isNoConnectionPageVisible: 'Show no connection page', // all true
    isSeoPagesContentOn: 'turn on/off seo pages content',
    isRealityCheckAvailable: 'enable Reality check popup', // all true
    /* L */
    languagesAvailable: 'languages available for an operator', // monte
    layout: 'Specifies which layout to use',
    layoutHomepage: 'Specifies which layout to use on homepage',
    limitsAndResponsibleGambling: 'keeps routes and components of Limits & Responsible Gambling', // all true but monte
    limitsAndResponsibleGamblingOnSignup: 'keeps routes and components of "Limits & Responsible Gambling" on signup',
    /* M */
    metaOpenGraph: 'apply meta open graph in html header',
    mobileFooterLinks: 'mobile footer links for all universes',
    /* N */
    navigationPopular: 'sports to show in popular section',
    navigationFavourites: 'show favourites section',
    navigationRecentlyViewed: 'show recently viewed section',
    navigationMobileLinks:
        'set mobile navigation links different to the top nav/header ones or the same like top nav/header ones',
    newPragmatic: 'switch between old and new pragmatic integration',
    notificationsList: 'switch to render notifications', // all true
    /* O */
    operatorFullName: 'Full name of each operator',
    outsideAllowedRoutes:
        'Routes from external server with the same domain which are used for promotion, news, blogs etc', // all the same
    /* P */
    page404Default: 'add additional elements and styles when 404 error page is not a default one',
    premierLeagueIframe: 'Show premier league iframe on sports/premier-league endpoint',
    problemGambling: 'Show Problem Gambling link in responsible gambling section',
    promotedEvents: "Show promoted events in LHS menu and main page and set route'accountHelperMail",
    /* R */
    raceSummaryRowHorseRacingNew: 'turns on new component (with increased silk) for horse racing selection',
    responsibleGamblingVerificationScript: 'show responsible gambling logotypes on footer',
    /* S */
    seoContent: 'seo headers and content',
    seoMeta: 'seo title, description',
    seoMetaSport: 'seo headers, title, description for sport',
    scoreboard:
        'set scoreboard version: basic (only score and time), standard (old scoreboard with animation), liveTracker (new live tracker with animation), lSport (LSport tennis scoreboard), imgArena for golf',
    sentryUrl: 'Sentry error tracker url', // all the same
    siteVerificationMetaMap: 'header meta css',
    statsCoreGroupId: 'Set groupId from Statscore to see pre-match widget and live match widget for tennis and golf.', // all the same
    socialWidgets: 'configuration social widget on RHS menu',
    stickyDesktopHeader: 'Sticky main header',
    /* T */
    termsAndConditionsDownloadLink: 'option to download T&C',
    traderChat: 'turn on/off trader chat',
    /* T */
    unSupportedBrowsers: 'turn on component for unSupportedBrowsers',
    unSupportedBrowsersLogo: 'set logo for unSupportedBrowsers component',
    /* W */
    weatherData: 'show or hide weather details',
    worldCupIframe: 'show world cup 2022 iframe',

    // PLAYERS CONFIG
    /* A */
    accountHelperMail: 'account for help contact',
    accountIdPrefix: 'prefix for account Id',

    /* C */
    casinoPublishedCollections: 'defines published casino collections',
    contactPreferences: 'defines contact preferences options',
    countryOnlyDefault: 'only default country in signup', // monte
    countrySignUpDefault: 'default country in sign up',
    cryptoOperatorAccountType: 'diffrent account tabs',
    currencyDefault: 'default currency',
    currencyDefaultSelectedInSignup: 'have default currency selected in signup',
    currencyOnlyDefault: 'only default currency in signup', // latam
    currencySignUpList: 'list of currencys in signup',
    customerFundsProtection: 'disable customer funds protection tab',
    /* D */
    decimalLength: 'specify how many places after comma we want to have in value',
    decimalFormatTextForPlaceholder: 'specify how we will display stake input placeholder text',
    detectLoginInMobileApp: 'detect login after signup in mobile app',
    depositLimitsType: 'ui change for picking limits based on calendar or time window (rolling)',
    depositsTurnOffForLeavingOperator: 'turn off deposits for the leaving operator',
    /* E */
    emailKycWarning: 'email to kyc warning',
    /* F */
    forgotPasswordInputLabel: 'input label type in forgot password form',
    forgotPasswordInputType: 'input type in forgot password form `email` | `text`',
    failedVerificationDetailed: '@description detailed failed verification page in sign up journey',
    faqSection: 'turn on/off faq section for authorized nad non authorized user.',
    /* H */
    hasCashoutEnabled: 'turn on/off able to cashout', // all true
    hasNeedHelpAccountSection: 'turn on/off section with help in account', // all true
    hasUserAddressChangeEnabled: 'turn on/off able to change address', // all true
    hasUserContactNumberChangeEnabled: 'turn on/off able to change contact number', // all true
    hasUserEmailChangeEnabled: ' turn on/off able to change email address', // all true
    hideWithdrawFundsPopupOnSignup: 'hide withdrawFunds popup on signup - Callum request',
    /* I */
    isVerifyAccountWithSMSAvailable: 'enable the account verification via SMS',
    intercomChat: 'enable intercom Chat',
    /* L */
    loginOnResetPasswordSuccess: 'autologin after reset password success',
    licenseName: 'license name',
    lifeSpanActive: 'use lifeSpan',
    lifeSpanSocketUrls: 'Websockets urls to lifespan',
    /* M */
    minDepositAmount: 'minimum deposit amount',
    minWithdrawAmount: 'minimum withdraw amount',
    /* N */
    navigationHelpSupport: 'show help and support section',
    /* P */
    payoutRules: 'show component with payout rules text',
    prefixAndFlagDefaultForPersonalDetails: 'Display only one flag and prefix in personal details page', // monte
    prefixAndPhoneNumberDefault: 'show default prefix for personal details in account',
    prefixMultiPhoneNumber: 'Show dropdown for mobile prefixes in registration and settings', // all true
    priorityPrefixes: 'priority prefixes that are in the front of phone number prefixes list',
    pof: 'Proof of Funds features',

    /* R */
    resetPasswordForm: 'reset password form ResetPasswordForm | McBookieValidateAccountForm',
    ringFencedFundsSetByDefault: 'set ringFencedFunds by default during signup',
    rollingNetDepositLimit:
        'turn on rolling deposit limits in signup and responsible gambling tab plus check it when user logs in',
    rollingNetDepositLimitFirstTimePopupType:
        'popup for users after adding new feature to operators - only when feature was created',

    /* S */
    showKycStatus: 'show KycStatus',
    showKycDepositWaring: 'show Kyc warning in deposit tab',
    signUpDisableButtonYourDetails: 'signUp disable button YourDetails', //all false
    signUpFooterMail: '',
    signUpShowPromoCode: '',
    signUpAddressSearchByPostCode: 'turn on/off searching addres by postcode in singup procedure', // monte
    signUpOmitAgeVerification: 'pass registration without checking age verification',
    signUpSecondAddressLine: 'show second line option to choose during signup',
    signUpTitle: 'show title option to choose during signup',
    signupType: 'signup journey type',
    signupPrivacyPolicyLink: 'show signup privacy policy link on signup',
    signupTurnOffForLeavingOperator: 'turn off signup for leaving operator',
    signUpCheckboxTC: 'signup checkbox for Terms & Conditions',
    srgQuestionnaire: 'show the SRG Questionnaire form modal',
    /* T */
    temporaryDepositPage: 'Show temporary deposit page',
    temporaryWithdrawalPage: 'Show temporary withdrawal page',
    timeoutToTestSignupFlow: 'use to change customer settings in BO', // dev for all
    /* U */
    usePaymentFormForUniverse: 'choose the payment provider',
    useOpenBanking: 'Open Banking Payment provider',
    usesCrypto: 'changes way of adding money to the balance',
    userCanChangeRealityCheck: 'user can change Reality Check duration',
    /* Z */
    zipCodeAndCountryCheck: 'Changed name of postcode to zip code and validate signup dropdown field',

    // SPORTSBOOK CONFIG
    /* B */
    buildRacecardCheckboxColorMode: 'set checkbox colors for Build Racecard',

    /* C */
    couponForEnv: 'coupon for environment',
    /* E */
    eventsEuropeanEliteMcbookie: 'get ids europeanEliteTemplate from template mcbookie', // 4 years ago for mcb - check if still needed
    eventsOnLaterHomePage: 'if route is starEvents show only eventsOnlater in mid layout',
    excludedCountriesFromSignUp: 'Remove specific countries from signup page',
    /* H */
    hasEventLiveStreaming: 'show icons and buttons for live event stream',
    hasInPlayDelayInformation: 'turn on delay information in InPlayComponent', // all true
    hasTopUpEnabled: 'turn on/off top up in betslip', // all true
    /* I */
    isAlternativeRacingBoost: 'display alternative price boosts',
    isAntePostReverse: 'add icon ios in headerMeta',
    isAutomaticCarousel: 'should promo carousel be automatic',
    isStreamControlledByTime: 'decide if stream component on Special Event should be controlled by time', // all false
    isStreamVisibleForUnauthorized: 'decide if stream component should be visible for unauthorized users', // all true
    /* L */
    liveTracker: 'live tracking scoreboard configuration - provider name and script src',
    liveTrackerForFootballOutrights: 'Specifies whether live tracker should be displayed for football outrights',
    /* N */
    noBetslip: 'universe has no betslip',
    nextRacesNew: 'turn on new view for Next Races (next off)',
    /* O */
    oddsFormatShortDefault: 'default odds for anonymous user',
    /* P */
    priceHistoryLineChart: 'show price history line chart in racing', // all true but LK that does not have races on
    /* R */
    rab: 'turn on RAB events',
    rabCashoutAvailable: 'decscription in YouDecideHeader (Unavailable for cashout)',
    rabHeaderIcon: 'BetBuilderIcon', // all the same
    rabHeaderTitle: 'title for rab header and bets',
    racingNextOffToggle: 'set switch between HR and GR next off',
    racingPageTabNames: 'set names for racing page filter tabs',
    /* S */
    shouldDisplaySilkWithoutQuestionMark: 'turn on/off render seats on live-casino games covers',
    showHorseRacingSortDropdown: 'show sort dropdown on Horse Racing Racecard',
    shouldPlaceBetToWatchStream: 'decide if user has to place a bet to enable stream',
    sliceNameRaceLabel: 'turn on slice name in race label',
    sportGreyhoundMeetingsSplitByCountry: 'decide if GR sport meetings are splitted by country',
    sportHorseracingMeetingsSplitByCountry: 'decide if HR sport meetings are splitted by country',
    sportsNamesCapitalized: 'make sport names in LHS capitized', // monte
    startingSoonEvents: 'show starting soon events on homepage',
    /* T */
    trapChallenge: 'enable Trap Challenge',
    /* U */
    universeSetup: '', //sbk
    /* W */
    withdrawValidateBtcAddress: 'determines whether to validate the wallet address in BTC',
};

/*
    Prod - Star
host: "dga.pragmaticplaylive.net",
casinoId: "ppcdg00000004198",

    Prod - Mcbookie
host: "dga.pragmaticplaylive.net",
casinoId: "ppcdg00000004197",

    Prod - NeBet
host: "dga.pragmaticplaylive.net",
casinoId: "ppcdg00000004199",

    STG - NeBet
host: "prelive-dga.pragmaticplaylive.net",
casinoId: "ppcdk00000005026",

    STG - Mcbookie
host: "prelive-dga.pragmaticplaylive.net",
casinoId: "ppcdk00000005025",
    */

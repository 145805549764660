import { CountryCodeZod } from 'src/domains/layouts/config/countries';
import { CurrencyCodeZod } from 'src_common/common/amount/website-money/currency';
import { ZodCatch, z } from 'zod';
import { zodToJsonSchema /*, ignoreOverride*/ } from 'zod-to-json-schema';

type NoUndefinedType<T> = T extends undefined ? never : T;

const describe = <T>(
    describe: string,
    defaultValue: NoUndefinedType<T>,
    validator: z.ZodType<T>
): ZodCatch<z.ZodDefault<z.ZodType<T>>> => {
    return (
        validator
            .describe(describe)
            .default(defaultValue)
            // .title
            .catch((error) => {
                console.info(`${describe} =>, catch error`, error);
                return defaultValue;
            })
    );
};

const GameWrapperZod = z.enum(['slider', 'slider-grid', 'square', 'rectangle', 'numeric-trending']);

const GameThumbnailSettingsZod = z.object({
    wrapperType: GameWrapperZod,
    showModalOnMobile: z.boolean(),
    showLikeAndInfo: z.boolean(),
    showRealTimeLobby: z.boolean(),
    minBetOnly: z.boolean(),
    showJackpotBar: z.boolean(),
});

const GameThumbnailsSettingsZod = z.object({
    casino: GameThumbnailSettingsZod,
    'live-casino': GameThumbnailSettingsZod,
    virtuals: GameThumbnailSettingsZod,
});

const ImageOptimizationZod = z
    .union([
        // for prod
        z.object({
            type: z.literal('autoUrl'),
        }),

        // for localhost & stage
        z.object({
            type: z.literal('baseUrl'),
            url: z.string(),
        }),
    ])
    // disable optimization
    .nullable();

const ProviderZod = z.enum([
    'Evolution',
    'Ezugi',
    'Leap',
    'Nsoft',
    'Pragmatic',
    'SG Digital',
    'SoftSwiss',
    'Relax',
    'Blueprint',
]);

const SocketPragmaticZod = z.object({
    host: z.string(),
    casinoId: z.string(),
});

const FiltersSkinZod = z.enum(['primary', 'secondary', 'tertiary']);

const UsefulLinksKeysZod = z.enum([
    'about-us',
    'responsible-gambling',
    'faq',
    'privacy-policy',
    'terms-and-conditions',
    'betting-rules',
    'give-feedback',
    'affiliates',
]);

const FooterUsefulLinksItemZod = z.object({
    id: UsefulLinksKeysZod,
    order: z.number(),
    //TODO - przywrócić
    to: z.union([
        z.string(),
        z.object({
            account: z.literal('static'),
            static: z.string(),
        }),
    ]),
    target: z.enum(['_blank', '_self', '_parent', '_top']).nullish(),
    route: z.string().optional(),
    isStaticLink: z.boolean().optional(),
});

const FooterUsefulLinksZod = z.record(UsefulLinksKeysZod, FooterUsefulLinksItemZod);

const CollectionZod = z.enum(['casino', 'live-casino', 'virtuals']);
const CollectionWithBingoZod = z.enum([...CollectionZod.options, 'bingo']);

const HeaderLinksZod = z.enum([
    'sports',
    'in-play',
    'lucky6',
    'mcbookie-blog',
    'star-news',
    'rhino-promotions',
    'dragonbet-blog',

    'bingo',

    'casino',
    'live-casino',
    'virtuals',
]);

const SingleOGTagPropertyZod = z.object({
    property: z.string(),
    content: z.string(),
});

const SingleOGTagNameZod = z.object({
    name: z.string(),
    content: z.string(),
});

const MetaOpenGraphTagsZod = z.union([SingleOGTagPropertyZod, SingleOGTagNameZod]);

const CollectionWithBingo = z.enum([...CollectionZod.options, 'bingo']);

const MobileFooterLinksZod = z.enum([
    'cash-out',
    'football-european',
    'football',
    'home',
    'in-play',
    'notifications',
    'offers',
    'promos',
    'racing',
    'sports-list',
    'sportsbook',
    'extra',
    ...CollectionWithBingo.exclude(['virtuals']).options,
]);

// SPORTSBOOK TYPES
const ListOfSportsZod = z.enum([
    'americanfootball',
    'australianrules',
    'badminton',
    'baseball',
    'basketball',
    'boxing',
    'cricket',
    'cycling',
    'darts',
    'esports',
    'football',
    'formulaone',
    'gaelicfootball',
    'gaelichurling',
    'golf',
    'greyhoundracing',
    'handball',
    'horseracing',
    'horseracingantepost',
    'icehockey',
    'internationalhorseracing',
    'mixedmartialarts',
    'motorsport',
    'olympics',
    'politics',
    'rugbyleague',
    'rugbyunion',
    'snooker',
    'tabletennis',
    'tennis',
    'tennisdoubles',
    'tvspecials',
    'volleyball',
]);

const PromotedEventRouteZod = z.enum([
    'aks-big-prices',
    'specials',
    'betzoneevents',
    'starevents',
    'yeeehaaaevents',
    'welshevents',
    'promoted-events',
    '',
]);

const SeoContentType = z.record(
    z.string(),
    z.object({
        header: z.string(),
    })
);

const SeoMetaValueZod = z.object({
    title: z.string(),
    description: z.string(),
});

const SeoMetaZod = z.object({
    homepage: SeoMetaValueZod.nullable(),
    inplay: SeoMetaValueZod.nullable(),
    sports: SeoMetaValueZod.nullable(),
    'promoted-events': SeoMetaValueZod.nullable(),
    casino: SeoMetaValueZod.nullable(),
    'live-casino': SeoMetaValueZod.nullable(),
    virtuals: SeoMetaValueZod.nullable(),
});

const SeoMetaSportZod = z.record(
    z.string(),
    z.object({
        title: z.string(),
        description: z.string(),
    })
);

const ContactPreferencesZod = z.enum(['email', 'sms', 'phone', 'whatsApp']);

const PaysafePaymentProviderZod = z.object({
    type: z.literal('paysafe'),
    apiKey: z.string(),
    environment: z.enum(['TEST', 'LIVE']),
    cardAccounts: z.object({
        GBP: z.string(),
        EUR: z.string(),
    }),
});

const PaymentProvidersZod = z.union([
    z.object({
        type: z.literal('realex'),
    }),

    z.object({
        type: z.literal('swiftyGlobalProvider'),
    }),

    z.object({
        type: z.literal('praxis'),
    }),

    PaysafePaymentProviderZod,
]);

const YaspaOpenBankingPaymentProvider = z.object({
    type: z.literal('yaspa'),
    isEuroAllowed: z.boolean(),
});

const OpenBankingProvidersZod = YaspaOpenBankingPaymentProvider.nullable();
export type OpenBankingProvidersType = z.infer<typeof OpenBankingProvidersZod>;

const MatchTypeZod = z.enum([
    'match-winner',
    'both-teams-to-score',
    'total-goals-over-under-2.5',
    'total-goals-over-under-1.5',
    'total-goals-over-under-0.5',
    'draw-no-bet',
    '1st-half-winner',
    'match-result-and-both-to-score',
]);

//..............................................................................

export const ConfigFeaturesZod = z.object({
    //..............................................................................
    //..............................................................................
    // CASINO CONFIG
    //..............................................................................
    //..............................................................................

    /**
     * define where antillephone icon should be displayed
     */
    antillephoneDisplaySector: describe(
        'define where antillephone icon should be displayed',
        null,
        z.enum(['license', 'responsible-gambling']).nullable()
    ),

    /**
     * set casino jack pot for each operator
     */
    casinoJackPot: describe('set casino jack pot for each operator', false, z.boolean()),

    /**
     * set casino mini games for each operator
     */
    casinoMiniGames: describe('set casino mini games for each operator', false, z.boolean()),

    /**
     * turn on/off game status bar for slots
     */
    gameStatusBar: describe('turn on/off game status bar for slots', false, z.boolean()),

    /**
     * defines what features should be displayed on SingleGameExt
     */
    gameThumbnailSettings: describe(
        'defines what features should be displayed on SingleGameExt',
        {
            casino: {
                wrapperType: 'slider',
                showModalOnMobile: false,
                showLikeAndInfo: false,
                showRealTimeLobby: false,
                minBetOnly: false,
                showJackpotBar: false,
            },
            'live-casino': {
                wrapperType: 'slider',
                showModalOnMobile: false,
                showLikeAndInfo: false,
                showRealTimeLobby: false,
                minBetOnly: false,
                showJackpotBar: false,
            },
            virtuals: {
                wrapperType: 'slider',
                showModalOnMobile: false,
                showLikeAndInfo: false,
                showRealTimeLobby: false,
                minBetOnly: false,
                showJackpotBar: false,
            },
        },
        GameThumbnailsSettingsZod
    ),

    /**
     * turn on/off users name in continue playing headline
     */
    hasContinuePlayingName: describe('turn on/off users name in continue playing headline', false, z.boolean()),

    /**
     * turn on/off games on homepage
     */
    hasHeadlinePromo: describe('turn on/off games on homepage', false, z.boolean()),

    /**
     * turn on/off games on homepage
     */
    hasGamesOnHomepage: describe('turn on/off games on homepage', false, z.boolean()),

    /**
     * show / hide all casino game on Christmas day
     */
    hideAllCasinoGamesOnChristmas: describe('show / hide all casino game on Christmas day', false, z.boolean()),

    /**
     * turn on/of image optimization with Cloudflare
     */
    imageOptimization: describe('turn on/of image optimization with Cloudflare', null, ImageOptimizationZod),

    /**
     * @description list of providers that require reload game after deposit
     */
    reloadGameAfterDepositForProviders: describe(
        'list of providers that require reload game after deposit',
        [],
        z.array(ProviderZod)
    ),

    /**
     * @description DGA for live casino
     */
    socketPragmatic: describe('DGA for live casino', null, SocketPragmaticZod.nullable()),

    //..............................................................................
    //..............................................................................
    // GROWTH CONFIG
    //..............................................................................
    //..............................................................................

    /**
     * @description New account drawer
     */
    accountDrawerNew: describe('New account drawer', false, z.boolean()),

    /**
     * @description Link for mobile app in store
     */
    appDownloadLink: describe(
        'Link for mobile app in store',
        {
            appStoreLink: null,
            googlePlayLink: null,
        },
        z.object({
            appStoreLink: z.string().nullable(),
            googlePlayLink: z.string().nullable(),
        })
    ),

    /**
     * @description Operator's cookiebot cookie consent banner id.
     */
    cookieBotId: describe("Operator's cookiebot cookie consent banner id", null, z.string().nullable()),

    /**
     * @description contact possibilities for Contact Us section in the main footer
     */
    contactDetails: describe(
        'contact possibilities for Contact Us section in the main footer',
        {
            phoneNumber: null,
            email: null,
            facebookLink: null,
            twitterLink: null,
            instagramLink: null,
        },
        z.object({
            phoneNumber: z.string().nullable(),
            email: z.string().nullable(),
            facebookLink: z.string().nullable(),
            twitterLink: z.string().nullable(),
            instagramLink: z.string().nullable(),
        })
    ),

    /**
     * @description temporary contact us list
     */
    contactUsTemporaryDefaultMock: describe(
        'temporary contact us list',
        [],
        z.array(
            z.object({
                contact_type: z.string(),
                contact_value: z.string(),
                display_order: z.number(),
                id: z.number(),
                is_active: z.boolean(),
                label: z.string().nullable().optional(),
                universe: z.string(),
            })
        )
    ),

    /**
     * @description set proper filters version
     */
    filtersVersion: describe(
        'set proper filters version',
        {
            betSlipTab: 'primary',
            casino: 'primary',
            football: 'primary',
            raceCardCompetitionTab: 'primary',
            raceCardMarketTab: 'primary',
            inPlay: 'primary',
            startingSoon: 'primary',
            eventsUpcoming: 'primary',
            eventsOnLater: 'primary',
            sportEvent: 'primary',
            competitionPage: 'primary',
            transactionHistory: 'primary',
            credits: 'primary',
        },
        z.object({
            /** @description Filters on Bets tab => Betslip | My bets */
            betSlipTab: FiltersSkinZod,
            casino: FiltersSkinZod,
            /** @description only football special filter skin */
            football: FiltersSkinZod,
            raceCardCompetitionTab: FiltersSkinZod,
            raceCardMarketTab: FiltersSkinZod,
            inPlay: FiltersSkinZod,
            startingSoon: FiltersSkinZod,
            eventsUpcoming: FiltersSkinZod,
            eventsOnLater: FiltersSkinZod,
            sportEvent: FiltersSkinZod,
            competitionPage: FiltersSkinZod,
            transactionHistory: FiltersSkinZod,
            credits: FiltersSkinZod,
        })
    ),

    /**
     * @description set footer links for CEG verification
     */
    footerCEGLinks: describe(
        'set footer links for CEG verification',
        null,
        z
            .object({
                iconUrl: z.string(),
                verificationUrl: z.string(),
            })
            .nullable()
    ),

    /**
     * @description temporary footer copyright
     */
    footerCopyrightTemporaryDefaultMock: describe(
        'temporary footer copyright',
        {
            description_text: null,
            has_cookies: false,
            id: 0,
            licence_number: '',
            licence_url: null,
        },
        z.object({
            description_text: z.string().nullish(),
            has_cookies: z.boolean(),
            id: z.number(),
            licence_number: z.string(),
            licence_url: z.string().nullish(),
        })
    ),

    /**
     * @description Useful links section in footer
     */
    footerUsefulLinks: describe('Useful links section in footer', {}, FooterUsefulLinksZod),

    /**
     * @description FreshDesk chat config
     */
    freshDeskChat: describe(
        'FreshDesk chat config',
        null,
        z
            .object({
                token: z.string(),
                host: z.string(),
                widgetUuid: z.string(),
            })
            .nullable()
    ),

    /**
     * @description fullstory id to track users on website
     */
    fullstory: describe('fullstory id to track users on website', null, z.string().nullable()),

    /**
     * @description google tag to google tag manager (google analytics and google optimize should be added through google tag manager panel) for Prod and non Prod env
     */
    gtm: describe(
        'google tag to google tag manager (google analytics and google optimize should be added through google tag manager panel) for Prod and non Prod env',
        '',
        z.string()
    ),

    /**
     * @description google optimize experiment id for dev (google optimize should be added through google tag manager panel)
     */
    googleExperimentIdDev: describe(
        'google optimize experiment id for dev (google optimize should be added through google tag manager panel)',
        '',
        z.string()
    ),

    /**
     * @description google optimize experiment id for production (google optimize should be added through google tag manager panel)
     */
    googleExperimentIdProd: describe(
        'google optimize experiment id for production (google optimize should be added through google tag manager panel)',
        '',
        z.string()
    ),

    /**
     * @description has to collect info about signup on mobile app
     */
    sendInfoAboutSignUpToMobileApp: describe('has to collect info about signup on mobile app', false, z.boolean()),

    /**
     * @description turn on/off promo carousel view
     */
    hasCarouselPromo: describe('turn on/off promo carousel view', false, z.boolean()),

    /**
     * @description link general terms & conditions in footer copyright text
     */
    hasFooterCopyrightTCLink: describe('link general terms & conditions in footer copyright text', false, z.boolean()),

    /**
     * @description has XCM script in footer
     */
    hasXcmScript: describe('has XCM script in footer', false, z.boolean()),

    /**
     * @description header links for universes
     */
    headerLinks: describe('header links for universes', [], z.array(HeaderLinksZod)),

    /**
     * @description headerMetaScale
     */
    headerMetaScale: describe(
        'headerMetaScale',
        'width=device-width, initial-scale=1, shrink-to-fit=no',
        z.enum([
            'width=device-width, initial-scale=1, shrink-to-fit=no',
            'width=device-width, initial-scale=1, user-scalable=no',
            'width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no, viewport-fit=cover',
            'width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no',
            'width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover',
        ])
    ),

    /**
     * @description headerMetaTitleName
     */
    headerMetaTitleName: describe('headerMetaTitleName', '', z.string()),

    /**
     * @description Facebook domain verification meta tag
     */
    headerMetaFacebookDomainVerification: describe(
        'Facebook domain verification meta tag',
        null,
        z.string().nullable()
    ),

    /**
     * @description link to the operator's blog / news section
     */
    headerSpecialLink: describe(
        "link to the operator's blog / news section",
        null,
        z.enum(['mcbookieblog', 'starsportsbet']).nullable()
    ),

    /**
     * @description sports which has to be hidden
     */
    hiddenSportsList: describe('sports which has to be hidden', [], z.array(z.string())),

    /**
     * @description flag to hide sports with no events on A-Z sports list
     */
    hideEmptySports: describe('flag to hide sports with no events on A-Z sports list', false, z.boolean()),

    /**
     * @description add icon ios in headerMeta
     */
    isDarkUniverse: describe('add icon ios in headerMeta', false, z.boolean()),

    /**
     * @description show Gambling Comission logo in Responsible Gambling footer section
     */
    isGamblingComissionRGLogo: describe(
        'show Gambling Comission logo in Responsible Gambling footer section',
        false,
        z.boolean()
    ),

    /**
     * @description Show no connection page when user has no internet connection
     */
    isNoConnectionPageVisible: describe(
        'Show no connection page when user has no internet connection',
        false,
        z.boolean()
    ),

    /**
     * @description Show popup Reality Check for all pages excluded casino
     */
    isSeoPagesContentOn: describe('Show popup Reality Check for all pages excluded casino', false, z.boolean()),

    /**
     * @description Show popup Reality Check for all pages excluded casino
     */
    isRealityCheckAvailable: describe('Show popup Reality Check for all pages excluded casino', false, z.boolean()),

    /**
     * @description Show popup Reality Check for all pages excluded casino
     */
    languagesAvailable: describe(
        'Show popup Reality Check for all pages excluded casino',
        [],
        z.array(
            z.object({
                langCode: z.string(),
                langName: z.string(),
            })
        )
    ),

    /**
     * @description LuckyKing based layout
     */
    layout: describe('LuckyKing based layout', 'primary', z.enum(['primary', 'secondary'])),

    /**
     * @description Specifies which layout to use on homepage
     */
    layoutHomepage: describe('Specifies which layout to use on homepage', 'primary', z.enum(['primary', 'secondary'])),

    /**
     * @description keeps routes and components of "Limits & Responsible Gambling"
     */
    limitsAndResponsibleGambling: describe(
        'keeps routes and components of "Limits & Responsible Gambling"',
        false,
        z.boolean()
    ),

    /**
     * @description keeps routes and components of "Limits & Responsible Gambling" on sign up
     */
    limitsAndResponsibleGamblingOnSignup: describe(
        'keeps routes and components of "Limits & Responsible Gambling" on sign up',
        false,
        z.boolean()
    ),

    /**
     * @description Specifies whether live tracker should be displayed for football outrights
     */
    liveTrackerForFootballOutrights: describe(
        'Specifies whether live tracker should be displayed for football outrights',
        false,
        z.boolean()
    ),

    /**
     * @description apply meta open graph in html header
     */
    metaOpenGraph: describe('apply meta open graph in html header', null, z.array(MetaOpenGraphTagsZod).nullable()),

    /**
     * @description mobile footer links for all universes
     */
    mobileFooterLinks: describe('mobile footer links for all universes', [], z.array(MobileFooterLinksZod)),

    /**
     * @description sports to show in popular section. If needed it can be expanded for new sports
     */
    navigationPopular: describe(
        'sports to show in popular section. If needed it can be expanded for new sports',
        null,
        z.array(z.enum(['promotedevents', 'price-boost', ...ListOfSportsZod.options])).nullable()
    ),

    /**
     * @description show favourites section
     */
    navigationFavourites: describe('show favourites section', false, z.boolean()),

    /**
     * @description show recently viewed section
     */
    navigationRecentlyViewed: describe('show recently viewed section', false, z.boolean()),

    /**
     * @description set mobile navigation links different to the top nav/header ones or the same like top nav/header ones
     */
    navigationMobileLinks: describe(
        'set mobile navigation links different to the top nav/header ones or the same like top nav/header ones',
        'special-links',
        z.enum(['special-links', 'header-links'])
    ),

    /**
     * @description switch between old and new pragmatic integration
     */
    newPragmatic: describe('switch between old and new pragmatic integration', false, z.boolean()),

    /**
     * @description turn on new view for Next Races (Next Off)
     */
    nextRacesNew: describe('turn on new view for Next Races (Next Off)', false, z.boolean()),

    /**
     * @description switch to render notifications
     */
    notificationsList: describe('switch to render notifications', false, z.boolean()),

    /**
     * @description Full name of each operator
     */
    operatorFullName: describe('Full name of each operator', '', z.string()),
    /**
     * @description Routes from external server with the same domain which are used for promotion
     */
    outsideAllowedRoutes: describe(
        'Routes from external server with the same domain which are used for promotion',
        [],
        z.array(z.string())
    ),

    /**
     * @description add additional elements and styles when 404 error page is not a default one
     */
    page404Default: describe(
        'add additional elements and styles when 404 error page is not a default one',
        false,
        z.boolean()
    ),
    /**
     * @description show premier league iframe on sports/premier-league endpoint
     */
    premierLeagueIframe: describe('show premier league iframe on sports/premier-league endpoint', false, z.boolean()),
    /**
     * @description show Problem Gambling link in responsible gambling section
     */
    problemGambling: describe('show Problem Gambling link in responsible gambling section', false, z.boolean()),
    /**
     * @description Show promoted events in LHS menu and main page and set route
     */
    promotedEvents: describe(
        'Show promoted events in LHS menu and main page and set route',
        {
            label: '',
            route: '',
        },
        z.object({
            label: z.string(),
            route: PromotedEventRouteZod,
        })
    ),
    /** @description turns on new component (with increased silk) for horse racing selection */
    raceSummaryRowHorseRacingNew: describe(
        'turns on new component (with increased silk) for horse racing selection',
        false,
        z.boolean()
    ),
    /**
     * @description show responsible gambling logotypes
     */
    responsibleGamblingVerificationScript: describe(
        'show responsible gambling logotypes',
        'default',
        z.enum(['default', 'swifty-type'])
    ),

    /**
     * @description seo headers and content
     */
    seoContent: describe('seo headers and content', null, SeoContentType.nullable()),

    /**
     * @description seo headers, title, description
     */
    seoMeta: describe('seo headers, title, description', null, SeoMetaZod.nullable()),

    /**
     * @description seo headers, title, description for sport
     */
    seoMetaSport: describe('seo headers, title, description for sport', null, SeoMetaSportZod.nullable()),

    /**
     * @description set scoreboard mode:
     * basic: only score and time
     * standard: old scoreboard with animation
     * liveTracker: new live tracker with animation
     * lSport: LSport tennis and golf scoreboard
     * showMatchStats: Football match xg and other stats
     */
    scoreboard: describe(
        'set scoreboard mode: basic: only score and time, standard: old scoreboard with animation, liveTracker: new live tracker with animation, lSport: LSport tennis and golf scoreboard',
        {
            basic: null,
            standard: null,
            liveTracker: null,
            lSport: null,
            imgArena: null,
            showMatchStats: false,
        },
        z.object({
            basic: z.array(ListOfSportsZod).nullable(),
            standard: z.array(ListOfSportsZod).nullable(),
            liveTracker: z.array(ListOfSportsZod).nullable(),
            /**
             * @description LSport now is Statscore. For LSport eventId we have to add prefix m:eventId => example: "m:301832"
             */
            lSport: z.array(ListOfSportsZod).nullable(),
            imgArena: z.enum(['golf']).nullable(),
            showMatchStats: z.boolean(),
        })
    ),

    /**
     * @description Sentry error tracker link
     */
    sentryUrl: describe('Sentry error tracker link', null, z.string().nullable()),
    /**
     * @description header meta css
     */
    siteVerificationMetaMap: describe(
        'header meta css',
        null,
        z
            .object({
                name: z.string(),
                content: z.string(),
            })
            .nullable()
    ),
    /**
     * @description Add social widget on RHS menu
     */
    socialWidgets: describe(
        'Add social widget on RHS menu',
        {
            twitter: null,
        },
        z.object({
            twitter: z
                .object({
                    url: z.string(),
                    theme: z.enum(['light', 'dark']),
                })
                .nullable(),
        })
    ),

    /**
     * @description groupId (for the whole platform) from Statscore
     */
    statsCoreGroupId: describe(
        'groupId (for the whole platform) from Statscore',
        {
            preMatchPro: '',
            liveMatchPro: '',
        },
        z.object({
            /**
             * @description preMatchPro - pre match widget
             */
            preMatchPro: z.string().describe('preMatchPro - pre match widget'),
            /**
             * @description liveMatchPro - live match widget
             */
            liveMatchPro: z.string().describe('liveMatchPro - live match widget'),
        })
    ),
    /**
     * @description stickyDesktopHeader
     */
    stickyDesktopHeader: describe('stickyDesktopHeader', false, z.boolean()),
    /**
     * @description option to download T&C
     */
    termsAndConditionsDownloadLink: describe('option to download T&C', false, z.boolean()),

    /**
     * @description trader chat
     */
    traderChat: describe(
        'trader chat',
        {
            isOn: false,
            isBubbleChatButton: false,
        },
        z.object({
            /**
             * @description turn on/off trader chat
             */
            isOn: z.boolean().describe('turn on/off trader chat'),
            /**
             * @description turn on/off floating icon trader chat
             */
            isBubbleChatButton: z.boolean().describe('turn on/off floating icon trader chat'),
        })
    ),
    /**
     * @description turn on component for unSupportedBrowsers
     */
    unSupportedBrowsers: describe('turn on component for unSupportedBrowsers', false, z.boolean()),
    /**
     * @description set logo for unSupportedBrowsers component
     */
    unSupportedBrowsersLogo: describe(
        'set logo for unSupportedBrowsers component',
        'StarsportsLogoIconWrapper',
        z.enum(['StarsportsLogoIconWrapper', 'NebetLogoWrapper', 'McBookieLogoWrapper'])
    ),

    /**
     * @description enable showing weather data
     */
    weatherData: describe('enable showing weather data', false, z.boolean()),
    /**
     * @description enable showing world cup 2022 iframe
     */
    worldCupIframe: describe('enable showing world cup 2022 iframe', false, z.boolean()),

    //..............................................................................
    //..............................................................................
    // PLAYERS CONFIG
    //..............................................................................
    //..............................................................................

    /**
     * @description account for help contact
     */
    accountHelperMail: describe('account for help contact', '', z.string()),
    /**
     * @description prefix for account Id
     */
    accountIdPrefix: describe('prefix for account Id', '', z.string()),
    /**
     * @description id for antillephone if exists
     */
    antillephoneId: describe('id for antillephone if exists', null, z.string().nullable()),

    /**
     * @description accounts bonuse engine
     */
    bonuseEngine: describe('accounts bonuse engine', false, z.boolean()),

    /**
     * @description defines published casino collections
     */
    casinoPublishedCollections: describe('defines published casino collections', [], z.array(CollectionWithBingoZod)),
    /**
     * @description defines contact preferences options
     */
    contactPreferences: describe('defines contact preferences options', [], z.array(ContactPreferencesZod)),
    /**
     * @description only default country in signup
     */
    countryOnlyDefault: describe('only default country in signup', false, z.boolean()),
    /**
     * @description default country in sign up
     */
    countrySignUpDefault: describe('default country in sign up', null, CountryCodeZod.nullable()),
    /**
     * @description different account tabs
     */
    cryptoOperatorAccountType: describe('different account tabs', false, z.boolean()),
    /**
     * @description default currency
     */
    currencyDefault: describe('default currency', 'GBP', CurrencyCodeZod),
    /**
     * @description have default currency selected in signup
     */
    currencyDefaultSelectedInSignup: describe('have default currency selected in signup', false, z.boolean()),
    /**
     * @description only default currency in signup
     */
    currencyOnlyDefault: describe('only default currency in signup', false, z.boolean()),
    /**
     * @description list of currencies in signup
     */
    currencySignUpList: describe('list of currencies in signup', [], z.array(CurrencyCodeZod)),
    /**
     * @description disable customer funds protection tab
     */
    customerFundsProtection: describe('disable customer funds protection tab', false, z.boolean()),

    /**
     * @description specify how many places after comma we want to have in value
     */
    decimalLength: describe('specify how many places after comma we want to have in value', 0, z.number()),

    /**
     * @description specify how we will display stake input placeholder text
     */
    decimalFormatTextForPlaceholder: describe(
        'specify how we will display stake input placeholder text',
        '',
        z.string()
    ),
    /**
     * @description ui change for picking limits based on calendar or time window (rolling)
     */
    depositLimitsType: describe(
        'ui change for picking limits based on calendar or time window (rolling)  ',
        'rollingType',
        z.enum(['rollingType', 'calendarType'])
    ),

    /**
     * @description turn off deposits for the leaving operator
     */
    depositsTurnOffForLeavingOperator: describe(
        'turn off deposits for the leaving operator',
        { enabled: false },
        z.union([
            z.object({ enabled: z.literal(false) }),
            z.object({ enabled: z.literal(true), operatorName: z.string(), date: z.string() }),
        ])
    ),

    /**
     * @description detect login after signup in mobile app
     */
    detectLoginInMobileApp: describe('detect login after signup in mobile app', false, z.boolean()),

    /**
     * @description remove countries from signup dropdown
     */
    excludedCountriesFromSignUp: describe('remove countries from signup dropdown', [], z.array(z.string())),

    /**
     * @description email to kyc warning
     */
    emailKycWarning: describe('email to kyc warning', '', z.string()),

    /**
     * @description input label type in forgot password form
     */
    forgotPasswordInputLabel: describe(
        'input label type in forgot password form',
        'default',
        z.enum(['default', 'mcbookie'])
    ),

    /**
     * @description input type in forgot password form
     */
    forgotPasswordInputType: describe('input type in forgot password form', 'email', z.enum(['email', 'text'])),

    /**
     * @description detailed failed verification page in sign up journey
     */
    failedVerificationDetailed: describe('detailed failed verification page in sign up journey', false, z.boolean()),

    /**
     * @description turn on/off faq section
     */
    faqSection: describe(
        'turn on/off faq section',
        {
            isOnForAuthorized: false,
            isOnForNonAuthorized: false,
        },
        z.object({
            isOnForAuthorized: z.boolean(),
            isOnForNonAuthorized: z.boolean(),
        })
    ),

    /**
     * @description turn on/off able to cashout
     */
    hasCashoutEnabled: describe('turn on/off able to cashout', false, z.boolean()),

    /**
     * @description turn on/off section with help in account
     */
    hasNeedHelpAccountSection: describe('turn on/off section with help in account', false, z.boolean()),

    /**
     * @description turn on/off able to change address
     */
    hasUserAddressChangeEnabled: describe('turn on/off able to change address', false, z.boolean()),

    /**
     * @description turn on/off able to change contact number
     */
    hasUserContactNumberChangeEnabled: describe('turn on/off able to change contact number', false, z.boolean()),

    /**
     * @description turn on/off able to change email address
     */
    hasUserEmailChangeEnabled: describe('turn on/off able to change email address', false, z.boolean()),

    /**
     * @description hide withdrawFunds popup on signup - Callum request
     */
    hideWithdrawFundsPopupOnSignup: describe('hide withdrawFunds popup on signup - Callum request', false, z.boolean()),

    /**
     * @description enable the account verification via SMS
     */
    isVerifyAccountWithSMSAvailable: describe('enable the account verification via SMS', false, z.boolean()),

    /**
     * @description enable intercome chat
     */
    intercomChat: describe('enable intercome chat', false, z.boolean()),

    /**
     * @description - autologin after reset password success
     */
    loginOnResetPasswordSuccess: describe('autologin after reset password success', false, z.boolean()),

    /**
     * @deprecated - license
     */
    licenseName: describe('license', '', z.string()),

    /**
     * @deprecated - is lifeSpan active
     */
    lifeSpanActive: describe('is lifeSpan active', false, z.boolean()),

    /**
     * @deprecated - Websockets urls to lifespan
     */
    lifeSpanSocketUrls: describe('Websockets urls to lifespan', null, z.string().nullable()),

    /**
     * @description - minimum deposit amount
     */
    minDepositAmount: describe('minimum deposit amount', '0', z.string()),
    /**
     * @description - minimum withdraw amount
     */
    minWithdrawAmount: describe('minimum withdraw amount', '0', z.string()),

    /**
     * @description - show help and support section
     */
    navigationHelpSupport: describe('show help and support section', false, z.boolean()),

    /**
     * @description - show component with payout rules text
     */
    payoutRules: describe('show component with payout rules text', false, z.boolean()),

    /**
     * @description - show default prefix and flag for personal details in account
     */
    prefixAndFlagDefaultForPersonalDetails: describe(
        'show default prefix and flag for personal details in account',
        null,
        z
            .object({
                id: z.string(),
                prefix: z.string(),
                label: z.string(),
            })
            .nullable()
    ),

    /**
     * @description - show default prefix for personal details in account
     */
    prefixAndPhoneNumberDefault: describe(
        'show default prefix for personal details in account',
        {},
        z.record(z.string(), z.string())
    ),

    /**
     * @description - show dropdown for mobile prefixes in registration and settings
     */
    prefixMultiPhoneNumber: describe(
        'show dropdown for mobile prefixes in registration and settings',
        false,
        z.boolean()
    ),

    /**
     * @description - priority prefixes that are in the front of phone number prefixes list
     */
    priorityPrefixes: describe(
        'priority prefixes that are in the front of phone number prefixes list',
        [],
        z.array(z.string())
    ),

    /**
     * @description - Proof of Funds features
     */
    pof: describe('Proof of Funds features', false, z.boolean()),

    /**
     * @description - reset password form
     */
    resetPasswordForm: describe(
        'reset password form',
        'ResetPasswordForm',
        z.enum(['ResetPasswordForm', 'McBookieValidateAccountForm'])
    ),

    /**
     * @description - set ringFencedFunds by default during signup
     */
    ringFencedFundsSetByDefault: describe('set ringFencedFunds by default during signup', false, z.boolean()),

    /**
     * @description - turn on rolling deposit limits in signup and responsible gambling tab plus check it when user logs in
     */

    rollingNetDepositLimit: describe(
        'turn on rolling deposit limits in signup and responsible gambling tab plus check it when user logs in',
        false,
        z.boolean()
    ),

    /**
     * @description - popup for users after adding new feature to operators - only when feature was created
     */

    rollingNetDepositLimitFirstTimePopupType: describe(
        'popup for users after adding new feature to operators - only when feature was created',
        'primary',
        z.enum(['primary', 'secondary', 'tertiary'])
    ),

    /**
     * @description - show KycStatus
     */
    showKycStatus: describe('show KycStatus', false, z.boolean()),

    /**
     * @description - show Kyc warning in deposit tab
     */
    showKycDepositWaring: describe('show Kyc warning in deposit tab', false, z.boolean()),

    /**
     * @description - signUp disable button YourDetails
     */
    signUpDisableButtonYourDetails: describe('signUp disable button YourDetails', false, z.boolean()),

    /**
     * @description - signUpFooterMail
     */
    signUpFooterMail: describe('signUpFooterMail', '', z.string()),

    /**
     * @description - signUpShowPromoCode
     */
    signUpShowPromoCode: describe('signUpShowPromoCode', false, z.boolean()),

    /**
     * @description - turn on/off searching address by postcode in signup procedure
     */
    signUpAddressSearchByPostCode: describe(
        'turn on/off searching address by postcode in signup procedure',
        false,
        z.boolean()
    ),

    /**
     * @description - signup checkbox for Terms & Conditions
     */
    signUpCheckboxTC: describe('signup checkbox for Terms & Conditions', false, z.boolean()),

    /**
     * @description - pass registration without checking age verification
     */
    signUpOmitAgeVerification: describe('pass registration without checking age verification', false, z.boolean()),

    /**
     * @description - show second line option to choose during signup
     */
    signUpSecondAddressLine: describe('show second line option to choose during signup', false, z.boolean()),

    /**
     * @description - show title option to choose during signup
     */
    signUpTitle: describe('show title option to choose during signup', false, z.boolean()),

    /**
     * @description - pass registration without checking age verification
     */
    signupType: describe(
        'pass registration without checking age verification',
        'primary',
        z.enum(['primary', 'secondary'])
    ),

    /**
     * @description - show signup privacy policy link on signup
     */
    signupPrivacyPolicyLink: describe('show signup privacy policy link on signup', false, z.boolean()),

    /**
     * @description - turn off signup for the leaving operator
     */
    signupTurnOffForLeavingOperator: describe(
        'turn off signup for the leaving operator',
        { enabled: false },
        z.union([
            z.object({ enabled: z.literal(false) }),
            z.object({ enabled: z.literal(true), operatorName: z.string(), date: z.string() }),
        ])
    ),

    /**
     * @description - show the SRG Questionnaire form modal
     */
    srgQuestionnaire: describe('show the SRG Questionnaire form modal', false, z.boolean()),

    /**
     * @description - Show temporary deposit page
     */
    temporaryDepositPage: describe('Show temporary deposit page', false, z.boolean()),

    /**
     * @description - Show temporary withdrawal page
     */
    temporaryWithdrawalPage: describe('Show temporary withdrawal page', false, z.boolean()),

    /**
     * @description - -----FeaturesFlag => should be always FALSE-----
     */
    timeoutToTestSignupFlow: describe('-----FeaturesFlag => should be always FALSE-----', false, z.boolean()),

    /**
     * @description - Payment providers
     */
    usePaymentFormForUniverse: describe(
        'Payment providers',
        {
            type: 'realex',
        },
        PaymentProvidersZod
    ),

    /**
     * @description - Open Banking Payment provider
     */
    useOpenBanking: describe('Open Banking Payment provider', null, OpenBankingProvidersZod),

    /**
     * @description - changes way of adding money to the balance
     */
    usesCrypto: describe('changes way of adding money to the balance', false, z.boolean()),

    /**
     * @description - user can change Reality Check duration
     */
    userCanChangeRealityCheck: describe('user can change Reality Check duration', false, z.boolean()),

    /**
     * @description - Change to zip code name and signup validate dropdowns
     */
    zipCodeAndCountryCheck: describe('Change to zip code name and signup validate dropdowns', false, z.boolean()),

    //..............................................................................
    //..............................................................................
    // SPORTSBOOK CONFIG
    //..............................................................................
    //..............................................................................

    /**
     * @description - set checkbox colors for Build Racecard
     */
    buildRacecardCheckboxColorMode: describe(
        'set checkbox colors for Build Racecard',
        'light',
        z.enum(['dark', 'light'])
    ),

    /**
     * @description - coupon for environment
     */
    couponForEnv: describe(
        'coupon for environment',
        'couponsStar',
        z.enum(['couponsStar', 'couponsMcBookie', 'couponsNebet', 'couponsDragonBet'])
    ),

    /**
     * @description - get ids europeanEliteTemplate from template mcbookie
     */
    eventsEuropeanEliteMcbookie: describe('get ids europeanEliteTemplate from template mcbookie', false, z.boolean()),

    /**
     * @description - if route is starEvents show only eventsOnLater in mid layout
     */
    eventsOnLaterHomePage: describe('if route is starEvents show only eventsOnLater in mid layout', false, z.boolean()),

    /**
     * @description - show icons and buttons for live event stream
     */
    hasEventLiveStreaming: describe('show icons and buttons for live event stream', false, z.boolean()),

    /**
     * @description - turn on delay information in InPlayComponent
     */
    hasInPlayDelayInformation: describe('turn on delay information in InPlayComponent', false, z.boolean()),

    /**
     * @description - turn on/off top up in betslip
     */
    hasTopUpEnabled: describe('turn on/off top up in betslip', false, z.boolean()),

    /**
     * @description - display alternative price boosts
     */
    isAlternativeRacingBoost: describe('display alternative price boosts', false, z.boolean()),

    /**
     * @description - add icon ios in headerMeta
     */
    isAntePostReverse: describe('add icon ios in headerMeta', false, z.boolean()),

    /**
     * @description - should promo carousel be automatic
     */
    isAutomaticCarousel: describe('should promo carousel be automatic', false, z.boolean()),

    /**
     * @description - decide if stream component on Special Event should be controlled by time
     */
    isStreamControlledByTime: describe(
        'decide if stream component on Special Event should be controlled by time',
        false,
        z.boolean()
    ),

    /**
     * @description - decide if stream component should be visible for unauthorized users
     */
    isStreamVisibleForUnauthorized: describe(
        'decide if stream component should be visible for unauthorized users',
        false,
        z.boolean()
    ),

    /**
     * @description - live tracking scoreboard configuration - provider name and script src
     */
    liveTracker: describe(
        'live tracking scoreboard configuration - provider name and script src',
        null,
        z
            .object({
                providerName: z.string(),
                scriptSrcUrl: z.string(),
            })
            .nullable()
    ),

    /**
     * @description - universe has no betslip
     */
    noBetslip: describe('universe has no betslip', false, z.boolean()),

    /**
     * @description - default odds for anonymous user
     */
    oddsFormatShortDefault: describe('default odds for anonymous user', 'f', z.enum(['f', 'd'])),

    /**
     * @description - show price history line chart in racing
     */
    priceHistoryLineChart: describe('show price history line chart in racing', false, z.boolean()),

    /**
     * @description - -----FeaturesFlag => should be always FALSE-----
     */
    rab: describe('-----FeaturesFlag => should be always FALSE-----', false, z.boolean()),

    /**
     * @description - description in YouDecideHeader (Unavailable for cashout)
     */
    rabCashoutAvailable: describe('description in YouDecideHeader (Unavailable for cashout)', false, z.boolean()),

    /**
     * @description - rabHeaderIcon
     */
    rabHeaderIcon: describe('rabHeaderIcon', 'BetBuilderIcon', z.enum(['BetBuilderIcon'])),
    /**
     * @description - title for rab header and bets
     */
    rabHeaderTitle: describe('title for rab header and bets', '', z.string()),
    /**
     * @description - set switch between HR and GR next off
     */
    racingNextOffToggle: describe('set switch between HR and GR next off', false, z.boolean()),
    /**
     * @description - set names for racing page filter tabs
     */
    racingPageTabNames: describe(
        'set names for racing page filter tabs',
        'default',
        z.enum(['default', 'betzone-type'])
    ),

    /**
     * @description - if true, silk is displayed without question mark
     */
    shouldDisplaySilkWithoutQuestionMark: describe(
        'if true, silk is displayed without question mark',
        false,
        z.boolean()
    ),

    /**
     * @description - decide if user has to place a bet to enable stream
     */
    shouldPlaceBetToWatchStream: describe('decide if user has to place a bet to enable stream', false, z.boolean()),

    /**
     * @description - show sort dropdown on Horse Racing Racecard
     */
    showHorseRacingSortDropdown: describe('show sort dropdown on Horse Racing Racecard', false, z.boolean()),

    /**
     * @description - turn on slice name in race label
     */
    sliceNameRaceLabel: describe('turn on slice name in race label', false, z.boolean()),

    /**
     * @description - decide if GR sport meetings are splitted by country
     */
    sportGreyhoundMeetingsSplitByCountry: describe(
        'decide if GR sport meetings are splitted by country',
        false,
        z.boolean()
    ),

    /**
     * @description - decide if HR sport meetings are splitted by country
     */
    sportHorseracingMeetingsSplitByCountry: describe(
        'decide if HR sport meetings are splitted by country',
        false,
        z.boolean()
    ),

    /**
     * @description - decide if sports in LHS will have capitised names
     */
    sportsNamesCapitalized: describe('decide if sports in LHS will have capitised names', false, z.boolean()),

    /**
     * @description - show starting soon events on homepage
     */
    startingSoonEvents: describe('show starting soon events on homepage', false, z.boolean()),

    /**
     * @description - enable Trap Challenge
     */
    trapChallenge: describe('enable Trap Challenge', false, z.boolean()),

    /**
     * @description - universeSetup
     */
    universeSetup: describe('universeSetup', [], z.array(MatchTypeZod)),

    /**
     * @description - determines whether to validate the wallet address in BTC
     */
    withdrawValidateBtcAddress: describe(
        'determines whether to validate the wallet address in BTC',
        false,
        z.boolean()
    ),
});

export type ConfigFeaturesType = z.TypeOf<typeof ConfigFeaturesZod>;

export const getJsonSchemaFromConfigFeatures = (): unknown => {
    const addRequired = (schema: unknown): unknown => {
        console.info(JSON.stringify(schema, null, 4));

        const PropertiesZod = z.object({
            properties: z.record(z.string(), z.unknown()),
        });

        const schemaProperties = PropertiesZod.parse(schema);

        console.info(Object.keys(schemaProperties.properties));
        const propKeys: Array<string> = [...Object.keys(schemaProperties.properties)];

        if (schema === null || schema === undefined) {
            throw Error('aa');
        }

        //@ts-expect-error
        schema.required = propKeys;

        //@ts-expect-error
        schema.title = 'Tytuł schemy';

        return schema;
    };

    const jsonSchema = addRequired(zodToJsonSchema(ConfigFeaturesZod));

    return jsonSchema;
};
